<template>
  <div class="tc-interview">
    <!-- 头部 -->
    <div class="header" style="justify-content: space-between">
      <div class="header-operation" style="display: none">
        <ul>
          <li>
            <img src="../../../assets/images/user_information_print.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/user_information_batch.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/export.png" alt />
          </li>
          <li>
            <img src="../../../assets/images/user_information_query.png" alt />
          </li>
        </ul>
      </div>
      <div></div>
      <div class="header-tool">
        <div class="delbtn" @click="delDatas" style="margin-right: 20px">
          <img src="../../../assets/images/del.png" alt />
        </div>
        <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="晤谈内容" prop="title">
            <el-input v-model="formInline.title"></el-input>
          </el-form-item>
          <el-form-item label="科室" prop="department_name">
            <el-select v-model="formInline.department_name">
              <el-option
                v-for="(item, key) in deptList"
                :key="key"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门诊号/住院号" prop="account">
            <el-input v-model="formInline.account"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="real_name">
            <el-input v-model="formInline.real_name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="loadListData()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="晤谈内容"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="门诊号/住院号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="department_name"
          label="科室名称"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex == 0 ? "女" : "男" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="检查时间"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="showExamineBox(scope.row)"
              >查看</el-button
            >
            <el-button size="mini" type="plain" @click="del(scope.row)"
              >删除</el-button
            >
            <!-- <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="size"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="查看晤谈报告"
      :visible.sync="examineBox"
      width="50%"
      center
    >
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-col :span="12">
          <el-form-item label="用户姓名：">
            <span>{{ form.real_name }}</span>
          </el-form-item>
          <el-form-item label="科室名称：">
            <span>{{ form.department_name }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户性别：">
            <span>{{ form.real_name == 0 ? "女" : "男" }}</span>
          </el-form-item>
          <el-form-item label="创建日期：">
            <span>{{ form.create_time }}</span>
          </el-form-item>
        </el-col>
      </el-form>
      <div class="content">
        <el-table
          ref="multipleTable"
          :data="reportDataList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          border
          class="tableBox"
          height="400"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="question"
            label="题目名称"
            width="400"
            align="left"
            header-align="center"
          ></el-table-column>
          <el-table-column
            prop="answer"
            label="答案选项"
            align="left"
            header-align="center"
          ></el-table-column>
          <!-- <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="plain" @click="showTopicBox(scope.row,0)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button size="mini" type="plain" @click="delDetail(scope.row)">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examineBox = false">取 消</el-button>
        <el-button type="primary" @click="examineBox = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  listInterviewReport,
  getInterviewReport,
  delete_interview_log,
} from "@/api/information.js";
import { loadDepartmentData } from "@/api/comm.js";
export default {
  inject: ["reload"],
  data() {
    return {
      formInline: {
        account: "",
        department_name: "",
        real_name: "",
        title: "",
      },
      form: {
        real_name: "",
        department_name: "",
        sex: "",
        create_time: "",
      },
      data: [],
      tableData: [],
      reportDataList: [], // 模态框中的表格
      // 页码
      size: 15,
      page: 1,
      total: 0,
      examineBox: false, //控制模态框
      multipleSelection: [],
      deptList: [],
    };
  },
  created() {
    this.loadListData();
    this.getDept();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
      //console.log(p);
    },
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 加载数据
    loadListData() {
      let { title, account, real_name, department_name } = this.formInline;
      let data = {
        page: this.page,
        size: this.size,
        // "%-measure_title"
        condition: {
          "%-title": title,
          "%-account": account,
          "%-real_name": real_name,
          department_name: department_name,
        },
      };
      listInterviewReport(data).then((res) => {
        //console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.count * 1;
      });
    },
    // 显示模态框，查询晤谈报告个人详情
    showExamineBox(row) {
      //console.log(row);
      this.examineBox = true;
      getInterviewReport({ id: row.id }).then((res) => {
        //console.log(res);
        let {
          real_name,
          department_name,
          sex,
          create_time,
          data_list,
        } = res.data;
        this.form.real_name = real_name;
        this.form.department_name = department_name;
        this.form.sex = sex;
        this.form.create_time = create_time;
        this.reportDataList = data_list;
        //console.log("1", data_list);
      });
      //console.log(this.reportDataList);
    },
    // 全选删除
    delDatas() {
      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning",
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let idStr = this.multipleSelection.toString();
          delete_interview_log({ ids: idStr }).then((res) => {
            if (res.code == 400200) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.size = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.loadListData();
    },
    // 获取科室信息
    getDept() {
      // 获取所有科室
      loadDepartmentData().then((res) => {
        this.deptList = res.data.data;
      });
    },
    // 删除
    del(row) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delete_interview_log({ ids: row.id }).then((res) => {
            console.log(res);
            if (res.code == 400200) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.loadListData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less">
.tc-interview {
  // 头部
  .header {
    // width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 150px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .delbtn {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 150px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 模态框
  .el-dialog__wrapper {
    .content {
      min-height: 0;
    }
  }
  // 页码
  .page {
    display: flex;
    justify-content: flex-end;
    margin-top: -20px;
  }
}
</style>